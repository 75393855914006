header {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 16px;
  border-bottom: 1px solid;
}

.menu-left {
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  width: 70px;
  justify-content: flex-start;
}

.menu-right {
  display: flex;
  width: 70px;
  justify-content: flex-end;
}

.App {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  background: #ececec;
  padding: 0;
  margin: 0;
}