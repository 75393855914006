:root {
  --color-correct: #4ade80;
  --color-present: #fbbf24;
  --color-missing: #cccccc;
  --color-active: #cccccc;
  --color-tbd: #ffffff;
  --color-background: #cccccc;
}


.game {
  padding-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  width: fit-content;
  margin: 0;
}

.gameBoard {
  width: 350px;
  height: 420px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
}

.row {
  padding: 3px;
  display: flex;
  justify-content: center;
  width: fit-content;
}


.row.active {
  background: #cccccc;
}

.tile {
  border: 2px solid;
  align-items: center;
  background: var(--color-tbd);
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  display: flex;
  width: 40px;
  height: 40px;
  justify-content: center;
  padding: 5px;
  margin: 2px;
}

.correct {
  background: var(--color-correct) !important;
}

.present {
  background: var(--color-present) !important;
}

.missing {
  background: var(--color-missing) !important;
}